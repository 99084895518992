import { useEffect, useState } from "react";
import moment from "moment";
import "./careerApp.css";
import { useGetApplicantsMutation, useChangeApplicationStatusMutation } from "../../store/services/applicants";
import { useGetPositionsMutation } from "../../store/services/positionsApi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ApplicationAccordion from "../../components/ApplicantsAccordion";
import CustomDropdown from "../../components/CustomSelect";
import { useNavigate } from "react-router-dom";
import PaginationButtons from "../../components/Pagination";
import { FaSortAmountDownAlt, FaSortAmountDown } from "react-icons/fa";

import { useParams } from "react-router-dom";
import PdfViewer from "../../components/PdfViwer";

const filterOptions = [
  { label: "None", val: 0 },
  { label: "Joining date", val: 1 },
  { label: "Location", val: 2 },
  { label: "CTC", val: 3 },
];

const CareerApplicants = () => {
  //importing api mutation to call api and access data

  const { position, status } = useParams();
  const [defaultApplication, setdefaultApplication] = useState({});
  const [selectedApp, setSelectedApp] = useState({});
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    // status: "",
    role: position,
    pageSize: 100000,
    page: 0,
    sortBy: 0,
    orderByAsc: true,
    search: "",
  });

  const [getApplicants, { data: applicantsData, error: applicantsError, isError: isApplicantFetchError, isSuccess: isApplicantFetchSuccess }] =
    useGetApplicantsMutation();

  useEffect(() => {
    console.log("get ");
    getApplicants(filter);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isApplicantFetchSuccess) {
      setdefaultApplication(applicantsData?.[status]?.[0]);
      setSelectedApp(applicantsData?.[status]?.[0]);
    }
  }, [isApplicantFetchSuccess, applicantsData]);

  const refetch = () => {
    getApplicants(filter);
    window.scrollTo(0, 0);
  };

  return (
    <main className=" w-full  flex-col flex p-10 absolute left-0 top-0 bg-white">
      <h1 className="text-2xl font-bold inline-block">
        <span className="text-xl mr-4 font-bold inline-block cursor-pointer" onClick={() => navigate(-1)}>
          {" "}
          🔙{" "}
        </span>
        {status == "list0"
          ? "Applied"
          : status == "list1"
          ? "Interview/Task"
          : status == "list2"
          ? "Offered"
          : status == "list3"
          ? "Accepted"
          : status == "list6"
          ? "Shortlisted"
          : status == "list7"
          ? "Shelfed"
          : "Rejected"}{" "}
        Applicants
      </h1>
      {applicantsData && (
        <div className="parent flex ">
          <div className="left w-3/12">
            <div className="filters w-full relative my-5">
              <div className="search  flex-1">
                <div className="">
                  <div className="flex flex-col  items-start gap-2">
                    <div className="flex flex-col w-full">
                      <h2 className="font-semibold">Search</h2>
                      <div className="flex w-full">
                        <input
                          className="bg-gray-100 h-10 rounded-md px-3 w-full"
                          value={filter.search}
                          onChange={(e) => {
                            setFilter((val) => {
                              return { ...val, search: e.target.value };
                            });
                          }}
                          placeholder="Email / Name"
                        />
                        {/* <button
                      onClick={() => {
                        setFilter((val) => {
                          return { ...val, sortBy: (val.sortBy + 1) % 3 };
                        });
                      }}
                      className="w-full px-2 py-2  rounded-md ml-4 "
                    >
                      Sort By: {filter.sortBy == 0 ? "None" : filter.sortBy == 1 ? "Joining date" : "Location"}
                    </button> */}
                      </div>
                    </div>

                    <div className="flex flex-col w-full">
                      <h2 htmlFor="dropdown" className="block mb-2 text-medium font-semibold text-gray-700">
                        Filter:
                      </h2>
                      <div className="flex justify-between items-center w-full gap-6">
                        <select
                          id="dropdown"
                          value={filter.sortBy}
                          onChange={(e) => {
                            console.log("val ==", e.target.value);
                            setFilter((val) => {
                              return { ...val, sortBy: e.target.value };
                            });
                          }}
                          className="block w-full px-4 py-2 text-sm border border-black font-semibold rounded-md shadow-sm "
                        >
                          {filterOptions.map((option) => (
                            <option className="text-sm font-semibold" key={option.val} value={option.val}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div
                          className="text-xl cursor-pointer"
                          onClick={() =>
                            setFilter((val) => {
                              console.log("VALLL -", val);
                              return { ...val, orderByAsc: !val.orderByAsc };
                            })
                          }
                        >
                          {filter.orderByAsc ? <FaSortAmountDownAlt /> : <FaSortAmountDown />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-5">
                    <button className="bg-blue-400 w-full px-2 py-2 rounded-lg  text-white" onClick={() => refetch()}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full mt-5 h-[60vh] overflow-y-scroll overflow-x-hidden px-2">
              <ApplicationAccordion
                refetch={refetch}
                applicantsData={applicantsData?.[status]}
                defaultApplication={defaultApplication}
                selectedApplicant={selectedApp}
                setSelectedApplicant={setSelectedApp}
              />
            </div>
          </div>
          <div className="right w-3/6 flex-1">
            {/* <PDFViewer
                document={{
                  url: `https://admin.praan.io/pdf/${selectedApp.career_resume}`,
                }}
              /> */}
            {selectedApp && <PdfViewer url={`https://admin.praan.io/pdf/${selectedApp?.career_resume}`} />}
          </div>
        </div>
      )}
    </main>
  );
};

export default CareerApplicants;
