import React, { useState } from "react";
import { useGetContactsQuery, useProgressMutation } from "../../store/services/contact";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ContactDetails = () => {
  const { contactType } = useParams();
  const navigate = useNavigate();
  const { data: contactData, isLoading, isError, refetch } = useGetContactsQuery(contactType);
  const [progressMutation] = useProgressMutation();
  const [modalData, setModalData] = useState({
    isVisible: false,
    position: { x: 0, y: 0 },
    selectedRow: null,
  });
  const [selectdRowId, setSelectedRowId] = useState(null);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching contact details</div>;
  }

  const lists = [
    {
      label: "Not Contacted",
      queryKey: "notContacted",
    },
    {
      label: "Contacted",
      queryKey: "contacted",
    },
    {
      label: "Interested",
      queryKey: "interested",
    },
    {
      label: "Not Interested",
      queryKey: "notInterested",
    },
  ];

  const handleRightClick = (e, rowData) => {
    e.preventDefault();
    setSelectedRowId(rowData?.c_id);
    setModalData({
      isVisible: true,
      position: { x: e.pageX, y: e.pageY },
      selectedRow: rowData,
    });
  };

  const handleOptionClick = async (queryKey) => {
    const body = {
      contactType: queryKey,
      c_id: modalData.selectedRow.c_id,
    };

    try {
      await progressMutation(body);
      setModalData({ ...modalData, isVisible: false });
      refetch();
    } catch (error) {
      console.error("Error updating contact type:", error);
    }
  };

  return (
    <>
      <div
        className="w-full bg-white flex-col flex p-10 pb-0"
        onClick={() => {
          return setModalData({ ...modalData, isVisible: false }), setSelectedRowId(null);
        }}
      >
        <div className="filters w-full relative mt-2">
          <div className="flex items-center mb-5">
            <span className="text-xl mr-4 font-bold inline-block cursor-pointer" onClick={() => navigate(-1)}>
              🔙
            </span>
            <h1 className="text-2xl font-bold">Contacts</h1>
          </div>

          {/* Contact Table */}
          <div className="overflow-x-auto bg-white rounded-md  h-[80vh] overflow-auto">
            <table className="w-full table-fixed border-collapse border border-gray-300">
              <thead className="bg-blue-300 text-white sticky top-0">
                <tr className="font-bold">
                  <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Email</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Number</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Purpose</th>
                  <th className="border border-gray-300 px-4 py-2 w-1/3 text-left">Message</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Company</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Designation</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Country</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">City</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {contactData.map((data, index) => (
                  <tr
                    key={index}
                    className={`${selectdRowId === data.c_id ? "bg-blue-300" : index % 2 === 0 ? "bg-gray-100" : "bg-white"} text-sm align-top`}
                    onContextMenu={(e) => handleRightClick(e, data)}
                  >
                    <td className="border border-gray-300 px-4 py-2">{data.c_name}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.c_email}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.c_number}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.c_purpose}</td>
                    <td className="border border-gray-300 px-4 py-2 ">{data.c_message}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.company}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.designation}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.c_country}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.city}</td>
                    <td className="border border-gray-300 px-4 py-2">{data.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Modal */}
        </div>
        {modalData.isVisible && (
          <div
            className="absolute bg-white shadow-md rounded-md border p-2"
            style={{
              top: modalData.position.y - 80,
              left: modalData.position.x + 20,
              zIndex: 1000,
            }}
          >
            {lists.map((item) => (
              <div key={item.queryKey} className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleOptionClick(item.queryKey)}>
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContactDetails;
