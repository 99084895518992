import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const careerApis = createApi({
  reducerPath: "careerApis",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "http://localhost:3005/api/",
    // baseUrl: "http://localhost:3000",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    // login api call
    getApplicants: builder.mutation({
      query: (filter) => {
        return {
          url: `/careers/`,
          method: "get",
          params: filter,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    changeApplicationStatus: builder.mutation({
      query: (body) => {
        return {
          url: `/careers/?career_id=${body.careerId}&status=${body.status}`,
          method: "put",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
  }),
});

export const { useGetApplicantsMutation, useChangeApplicationStatusMutation } = careerApis;
