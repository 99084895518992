import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const businessApi = createApi({
  reducerPath: "businessApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "http://localhost:3005/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  // API endpoints
  endpoints: (builder) => ({
    // Fetch business API

    getBusinessContactCount: builder.query({
      query: () => {
        return {
          url: `/business/count`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error.data;
      },
    }),

    getBusiness: builder.query({
      query: (contactType) => {
        return {
          url: `/business/${contactType}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.contactData;
      },
      transformErrorResponse: (error) => {
        return error.data;
      },
    }),

    //change business Type
    progress: builder.mutation({
      query: (body) => {
        return {
          url: `/business`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error.data;
      },
    }),
  }),
});

export const { useGetBusinessContactCountQuery, useGetBusinessQuery, useProgressMutation } = businessApi;
