import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const contactApi = createApi({
  reducerPath: "contactApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "http://localhost:3005/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  // API endpoints
  endpoints: (builder) => ({
    // Fetch contact API

    getCountContacts: builder.query({
      query: () => {
        return {
          url: `/contacts/count`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error.data;
      },
    }),
    getContacts: builder.query({
      query: (contactType) => {
        return {
          url: `/contacts/${contactType}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.contactData;
      },
      transformErrorResponse: (error) => {
        return error.data;
      },
    }),

    //change contact Type
    progress: builder.mutation({
      query: (body) => {
        return {
          url: `/contacts`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error.data;
      },
    }),
  }),
});

export const { useGetCountContactsQuery, useGetContactsQuery, useProgressMutation } = contactApi;
