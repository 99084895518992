import { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";
import Modal from "./Modal";
import { useToast } from "@chakra-ui/react";

import { useChangeApplicationStatusMutation } from "../store/services/applicants";

const ApplicationAccordion = ({ applicantsData, refetch, defaultApplication, selectedApplicant, setSelectedApplicant }) => {
  //importing api mutation to call api and access data

  const [changeStatus, { data: applicationData, error: applicationError, isError: isapplicationFetchError, isSuccess: isChangeSuccess }] =
    useChangeApplicationStatusMutation();

  const [actionsOpen, setActionOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [selectedApplicant, setSelectedApplicant] = useState({});
  const toast = useToast();

  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
  };

  const handleStatusChange = (applicant, status) => {
    let body = { careerId: applicant.career_id, status: status };
    changeStatus(body);
  };

  useEffect(() => {
    if (isChangeSuccess) {
      setShowModal(false);
      toast({
        title: "Success.",
        // description: "We've created your account for you.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch();
    }
  }, [isChangeSuccess, applicationData]);

  return (
    <main className=" w-full cursor-pointer ">
      <div className="">
        {
          <div className="absolute top-5  right-10">
            {selectedApplicant && (
              <div className="actions flex w-full gap-5 justify-around ">
                {selectedApplicant.career_status !== "0" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "0")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Applied
                  </button>
                )}
                {selectedApplicant.career_status !== "7" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "7")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Shelf
                  </button>
                )}
                {selectedApplicant.career_status !== "6" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "6")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Shortlist
                  </button>
                )}
                {selectedApplicant.career_status !== "1" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "1")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Interview/task
                  </button>
                )}
                {selectedApplicant.career_status !== "2" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "2")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Offered
                  </button>
                )}
                {selectedApplicant.career_status !== "3" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "3")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Accepted
                  </button>
                )}
                {selectedApplicant.career_status !== "4" && (
                  <button onClick={() => handleStatusChange(selectedApplicant, "4")} className="bg-blue-400 w-full px-3 py-2 mt-3 rounded-md text-white">
                    Reject
                  </button>
                )}
              </div>
            )}
          </div>
        }

        <div>
          {applicantsData &&
            applicantsData.map((data) => {
              return (
                <div
                  onClick={() => setSelectedApplicant(data)}
                  className={` ${applicantsData.career_id == selectedApplicant?.career_id ? "bg-gray-300" : "bg-white"}  border rounded-md  p-3 my-3`}
                >
                  <div className="flex items-center pt-1">
                    <div borderRadius={"xl"} display={"flex"} alignContent={"center"} _hover={{ bgColor: "#fff" }}>
                      <div as="span" flex="1" textAlign="left" fontWeight={""} fontSize={"lg"}>
                        <h2 className="font-bold inline-block"> {data.career_name} </h2> | <span className="text-sm font-light"> {data.career_title}</span>
                        <div>
                          <h4 className="text-sm font-light">Joining Date - {data.joining_date}</h4>
                          <h4 className="text-sm font-light">
                            Expected CTC - {`${data?.currency_type === "rupee" ? "₹" : data?.currency_type === "dollar" ? "$" : ""}${data.exp_ctc}`}
                          </h4>
                          <h4 className="text-sm font-light"> Notice Period - {data.notice_period}</h4>
                        </div>
                      </div>
                      {/* <div>
                        <a target="_blank" className=" text-xs text-slate-500 ml-1" href={`https://admin.praan.io/pdf/${data.career_resume}`}>
                          <div className="w-40 py-2 text-md flex justify-center border-2 rounded-md font-semibold">Resume</div>
                        </a>
                      </div> */}
                    </div>
                  </div>

                  <div className=" relative mt-2 ">
                    <div className="name  justify-between items-center">
                      <div className="top  justify-between ">
                        <div marginBottom={"4"}>
                          <h4 className="text-sm font-light"> Email - {data.career_email}</h4>
                          <h4 className="text-sm font-light"> Work Type - {data.work_type}</h4>
                          <h4 className="text-sm font-light"> Country - {data.country}</h4>
                          <h4 className="text-sm font-light"> Applied Date - {data.date}</h4>
                          {data.description && (
                            <div className="bottom mt-2 ">
                              <h1 className="font-bold text-left   text-md mr-1">Description:</h1>
                              <p className="text-sm text-slate-500 ml-1">{data.description}</p>
                            </div>
                          )}
                          {data.application_count && (
                            <div className="text-sm font-light">Application count : {data.application_count}</div>
                          ) 
                          }
                           {data.application_count && (
                            <div className="text-sm font-light"> Last Applied on  : {data.last_application_date ? data.last_application_date : data.date}</div>
                          )
                          }
                        </div>
                        <div className="flex mt-3 gap-1">
                          {data.profile && (
                            <a target="_blank" className=" text-xs text-slate-500 ml-1" href={getClickableLink(data.profile)}>
                              <div className="px-2 py-2 text-md flex justify-center border-2 rounded-md font-semibold">LinkedIn Profile</div>
                            </a>
                          )}
                          {data.website && (
                            <a target="_blank" className=" text-xs text-slate-500 ml-1" href={getClickableLink(data.website)}>
                              <div className="px-2 py-2 text-md flex justify-center border-2 rounded-md font-semibold">Personal Website</div>
                            </a>
                          )}
                          {/* <button
                            onClick={() => {
                              setShowModal(true);
                              setSelectedApplicant(data);
                            }}
                            className=" w-40 py-2 text-white bg-blue-400 mt-4 text-md flex justify-center border-2 rounded-md font-semibold text-xs  "
                          >
                            Actions
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* <img className="w-1/2 max-w-[200px] mt-1" src={hiveImg} alt="" /> */}
                    <div className="action absolute right-2 top-2 flex text-xl"></div>
                  </div>
                </div>
              );
            })}

          {(!applicantsData || applicantsData.length == 0) && <h1 className="text-center font-semibold  text-xl"> No Applications Over Here</h1>}
        </div>
      </div>
    </main>
  );
};

export default ApplicationAccordion;
