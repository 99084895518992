import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetBusinessContactCountQuery } from "../../store/services/business";

const Business = () => {
  const { data, isLoading, isError, refetch } = useGetBusinessContactCountQuery();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const lists = [
    {
      label: "Not Contacted",
      queryKey: "notContacted",
    },
    {
      label: "Contacted",
      queryKey: "contacted",
    },
    {
      label: "Interested",
      queryKey: "interested",
    },
    {
      label: "Not Interested",
      queryKey: "notInterested",
    },
  ];
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching contact counts.</div>;
  }

  if (!data) {
    return <div>Loading contact data...</div>;
  }

  const handleBoxClick = (queryKey) => {
    navigate(`/business/${queryKey}`);
  };
  return (
    <>
      <main className="w-full bg-white flex-col flex p-10">
        <div className="filters w-full relative my-2">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Business</h1>
          </div>
          <div className="dropdown flex-1 mt-5">
            <div className="grid grid-cols-4 w-full gap-5 mt-5">
              {lists.map((list) => (
                <div key={list.queryKey} className="bg-blue-200 flex  justify-between p-3 py-4 rounded-lg cursor-pointer" onClick={() => handleBoxClick(list.queryKey)}>
                  <h1 className="font-normal ">{list.label}</h1>
                  <h1 className="font-bold ">{data[list.queryKey] || 0}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Business;
